import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import EmptyList from '../../components/common/Chip/EmptyList';
import { blogList } from '../../config/data';
import Chip from '../../components/common/Chip';
import './stylesBlog.css';

const Blog = () => {
  const {id} = useParams();
  const [blog, setBlog] = useState(null)

  useEffect(() => {
   let blog = blogList.find(blog => blog.id === parseInt(id));

    if(blog) {
      setBlog(blog);
    }

  }, [id])

  return (
    <>
      <Link className='blog-goBack center' to='/'>
        <span> &#8592; Home</span>
      </Link>
      {blog ? (
        <div className='blog-wrapper'>
          <header>
            <h1 className='myh2 myYellow'>Today's trading session</h1>
            <h3 className='tradingDate myh3 myPaddingBottom'><br /> {blog.tradingDate}</h3>
            <h3 className='sessionInstrument myh3 myPaddingBottom'><br />{blog.sessionInstrument}</h3>
            <h3 className='sessionNetProfit myh3 myPaddingBottom'><br />{blog.sessionNetProfit}</h3>
            <div className='blog-subCategory'>
              {blog.subCategory.map((category, i) => (
                <div key={i}>
                  <Chip label={category} />
                </div>
              ))}
            </div>
          </header>
          <div className='blockquoteBlog'>Trade what you see, not what you want to see!</div>
          <h3 className='myh3 myYellow headerMargin'>Summary & significant lesson of today</h3>
          <p className='summary'>{blog.summary}</p>
          <p className='sessionReflection'>{blog.sessionReflection}</p>
          <p className='todaysNews'>{blog.todaysNews}</p>
          <hr />
          <div className='tradeStats'>
          <h3 className='myh3 myYellow headerMargin'>Trade performance</h3>
              <p className='myParagraph'>This is a summary of my daily trade performance, on Fridays I will summarize the week. My main focus will be to trade with a winning percentage of +60% or higher for each day and week.</p>
              <div className='myListItem netProfitPerAccount'> {blog.netProfitPerAccount}</div>
              <div className='myListItem netProfitDollar'>{blog.netProfitDollar}</div>
              <div className='myListItem netProfitTicks'> {blog.netProfitTicks}</div>
              <div className='myListItem totalCommission'>{blog.totalCommission}</div>
              <br />
              <div className='myListItem numOfAccounts'>{blog.numOfAccounts}</div>
              <div className='myListItem tradesDuringSession'>{blog.tradesDuringSession}</div>
              <div className='myListItem numOfTradedContracts'>{blog.numOfTradedContracts}</div>
              <br />
              <div className='myListItem winPercentage'>{blog.winPercentage}</div>
              <br />
              <div className='myListItem numOfWinningTrades'>
                <span className='myListItem profitColor'>{blog.numOfWinningTrades}</span>
              </div>
              <div className='myListItem numOfLoosingTrades'>
                <span className='myListItem lossColor'>{blog.numOfLoosingTrades}</span>
              </div>
              <div className='myListItem numOfBE'>{blog.numOfBE}
                <span className='myParagraph mySmallText'> (BE or BE+1 trades)</span>
              </div>
              <br />  
              <div className='myListItem avgWinner'>
                <span className='myListItem profitColor'>{blog.avgWinner}</span>
              </div>
              <div className='myListItem avgLooser'>
                <span className='myListItem lossColor'>{blog.avgLooser}</span>
              </div>
              <br />
              <p className='summaryStats myParagraph'>{blog.summaryStats}</p>

              <img src={blog.tradePerformance} alt='Screenshot of my trade performance goes here...' />
              <h6 className='myh6 headerMargin myYellow'>Trade performance abbreviations</h6>
              <div className='explanation profitPerMonth'>This statistic returns a value that can be used as a performance measure for your strategy. It gives you an idea of how much profit you can expect to make per month. A month is defined as 30.5 days which found by the following: (number of days) / (number of months in a year considering leap year)</div>
              <div className='explanation MAE'>This statistic returns a value representing the average maximum run-down your trades experience. This information helps you gauge how poorly your entry conditions predict upcoming price movement directions. A low dollar amount here is desirable since it would imply that the price movement after you enter a position follows the direction of your intended trade.</div>
              <div className='explanation MFE'>This statistic returns a value representing the average maximum run-up your strategy experiences. This information helps you gauge how well your strategy’s entry conditions predict upcoming price movements. A high dollar amount here is desirable since it would imply high profitability opportunities.</div>
              <div className='explanation ETD'>This statistic returns a value that is useful in giving you a measure of how effective your exit conditions capture the price movements after your strategy enters a position. It shows you how much you give back from the best price reached before you exit the trade. A small number here is generally desirable since it would imply highly optimized exit conditions that capture most of the price movement you were after.</div>
              <div className='explanation avgTimeInMarket'>This statistic returns a value that gives you an idea of how long you can expect your positions to be open. You can use this by manually closing out a position if you feel it has been in the market for too long.</div>
              <div className='explanation winPercent'>Win percentage of session (should be over 60%).</div>
              <div className='explanation totalEfficiency'>The trades percentage of the total range during the trade (100% is entry at low/high and exit at high/low).</div>
              <div className='explanation avgTradeDollar'>This statistic returns a value representing the average profit you experience from all of your trades. It is useful for getting an idea of how much you could expect to earn on future trades.</div>
              <h3 className='myh3 myYellow headerMargin'>Current account state</h3>
              <img src={blog.accountSession} alt='Screenshot of my trade performance goes here...' />
          </div>
          <hr />

          <h3 className='myh3 myYellow headerMargin'>My session chart (multiple instruments)</h3>
          {/* <img className="myChart" src={blog.instrument2} alt='My chart marked up' /> */}
          <div className='img-wrapper'>
            <p className='myParagraph'>When I trade CL and ES I will post both charts here</p>
            <p className='significantDiff myParagraph'>{blog.significantDiff}</p>
            <img src={blog.instrument1} alt='Chart 1 goes here...' />
            <span>
            <img src={blog.instrument2} alt='Chart 2 goes here...' />
            </span> 
              {/* <div className='explanation yesterdaysCloseDarkOrange'>The goldenrod line displays yesterdays close.</div> 
              <div className='explanation todayOpenDarkRed'>The gold line displays todays open.</div>*/}
               {/*  <div className='explanation mySessionArea'>The white area displays my session (US open from 15:30-18:00 (CET +1 hour)).</div>*/}
          <div className='explanation mySessionArea'>I'm trading the US session between 15:30-22:30 (CET +1 hour).</div>
          </div>
          <br />
          {/* <h2 className='myh2 myYellow'>Significant chart differences</h2> */}
          {/*  */}
          <div className='myParagraph'>
            <p className='instrumentComparison'>{blog.instrumentComparison1}</p>
            <p className='instrumentComparison'>{blog.instrumentComparison2}</p>
            <p className='instrumentComparison'>{blog.instrumentComparison3}</p>
            <p className='instrumentComparison'>{blog.instrumentComparison4}</p>
            <p className='instrumentComparison'>{blog.instrumentComparison5}</p>
            <p className='instrumentComparison'>{blog.instrumentComparison6}</p>
            <p className='instrumentComparison'>{blog.instrumentComparison7}</p>
            <p className='instrumentComparison'>{blog.instrumentComparison8}</p>
            <p className='instrumentComparison'>{blog.instrumentComparison9}</p>
            <p className='instrumentComparison'>{blog.instrumentComparison10}</p>
          </div>
          <hr /> 
          {/* --------------- CHART 1 --------------- */}
           {/* CRUDE OIL --- CL */}
          <h2 className='myh2 myYellow'>Entry - 500 ticks</h2>
          <h5 className='myh5 chart1InstrumentType instrumentColorType'>{blog.chart1InstrumentType}</h5>
          <p className='myParagraph'></p>
          <h6 className='myh6'>Chart arrow color explanation</h6>
          <p className='explanation arrow arrowLime'>&#8593; Win - Long scalp</p>
          <p className='explanation arrow arrowRed'>&#8595; Win - Short scalp</p>
          <p className='explanation arrow arrowGreen'>&#8593;&#8595; An aggressive setup (aggressive signal bar but entry based on context)</p>
          <p className='explanation arrow arrowGold'>&#8593;&#8595; Loss - Full stop</p>
          {/*<p className='explanation arrow arrowCyan'>&#8593;&#8595; My actual trades.</p>
          <p className='myh4 mackComparison'>{blog.mackComparison}</p>*/}
          <br />
          
          <img src={blog.instrument1} alt='Chart 1 goes here...' />
            {/* <div className='explanation yesterdaysCloseDarkOrange'>The goldenrod line displays yesterdays close.</div>
            <div className='explanation todayOpenDarkRed'>The gold line displays todays open.</div>*/}
           {/*  <div className='explanation mySessionArea'>The white area displays my session (US open from 15:30-18:00 (CET +1 hour)).</div>*/}
          <div className='explanation mySessionArea'>I'm trading the US session between 15:30-22:30 (CET +1 hour).</div>
          <br />

          <p className='myh4 SignificantTimeStamps'></p>
          <div className='myParagraph'>
            <p className='chart1SummaryTimeStamp'>{blog.chart1SummaryTimeStamp1}</p>
            <p className='chart1SummaryTimeStamp'>{blog.chart1SummaryTimeStamp2}</p>
            <p className='chart1SummaryTimeStamp'>{blog.chart1SummaryTimeStamp3}</p>
            <p className='chart1SummaryTimeStamp'>{blog.chart1SummaryTimeStamp4}</p>
            <p className='chart1SummaryTimeStamp'>{blog.chart1SummaryTimeStamp5}</p>
            <p className='chart1SummaryTimeStamp'>{blog.chart1SummaryTimeStamp6}</p>
            <p className='chart1SummaryTimeStamp'>{blog.chart1SummaryTimeStamp7}</p>
            <p className='chart1SummaryTimeStamp'>{blog.chart1SummaryTimeStamp8}</p>
            <p className='chart1SummaryTimeStamp'>{blog.chart1SummaryTimeStamp9}</p>
            <p className='chart1SummaryTimeStamp'>{blog.chart1SummaryTimeStamp10}</p>
            <p className='chart1SummaryTimeStamp'>{blog.chart1SummaryTimeStamp11}</p>
            <p className='chart1SummaryTimeStamp'>{blog.chart1SummaryTimeStamp12}</p>
            <p className='chart1SummaryTimeStamp'>{blog.chart1SummaryTimeStamp13}</p>
            <p className='chart1SummaryTimeStamp'>{blog.chart1SummaryTimeStamp14}</p>
            <p className='chart1SummaryTimeStamp'>{blog.chart1SummaryTimeStamp15}</p>
            <p className='chart1SummaryTimeStamp'>{blog.chart1SummaryTimeStamp16}</p>
            <p className='chart1SummaryTimeStamp'>{blog.chart1SummaryTimeStamp17}</p>
            <p className='chart1SummaryTimeStamp'>{blog.chart1SummaryTimeStamp18}</p>
            <p className='chart1SummaryTimeStamp'>{blog.chart1SummaryTimeStamp19}</p>
            <p className='chart1SummaryTimeStamp'>{blog.chart1SummaryTimeStamp20}</p>
          </div>
          <br />
          <hr />

          {/* --------------- CHART 2 --------------- */}
          {/* S&P 500 --- ES */}
          <h2 className='myh2 myYellow'>Trend chart - 2000 ticks</h2>
          <h5 className='myh5 chart2InstrumentType instrumentColorType'>{blog.chart2InstrumentType}</h5>
          <p className='myParagraph'></p>
          <h6 className='myh6'>Chart arrow color explanation</h6>
          <p className='explanation arrow arrowLime'>&#8593; Win - Long scalp</p>
          <p className='explanation arrow arrowRed'>&#8595; Win - Short scalp</p>
          <p className='explanation arrow arrowGreen'>&#8593;&#8595; An aggressive setup (aggressive signal bar but entry based on context)</p>
          <p className='explanation arrow arrowGold'>&#8593;&#8595; Loss - Full stop</p>
          {/* <p className='explanation arrow arrowCyan'>&#8593;&#8595; My actual trades.</p>
          <p className='myParagraph'>I'm not currently trading the ES chart, I only focus on CL at the moment.</p> */}
          <br />
          <div><img src={blog.instrument2} alt='Chart 2 goes here...' /></div>
          {/* <div className='explanation yesterdaysCloseDarkOrange'>The goldenrod line displays yesterdays close.</div> 
          <div className='explanation todayOpenDarkRed'>The gold line displays todays open.</div>*/}
          {/*  <div className='explanation mySessionArea'>The white area displays my session (US open from 15:30-18:00 (CET +1 hour)).</div>*/}
          <div className='explanation mySessionArea'>I'm trading the US session between 15:30-22:30 (CET +1 hour).</div>
          <br />
          <p className='myh4 SignificantTimeStamps'></p>
          <div className='myParagraph'>
            <p className='chart2SummaryTimeStamp'>{blog.chart2SummaryTimeStamp1}</p>
            <p className='chart2SummaryTimeStamp'>{blog.chart2SummaryTimeStamp2}</p>
            <p className='chart2SummaryTimeStamp'>{blog.chart2SummaryTimeStamp3}</p>
            <p className='chart2SummaryTimeStamp'>{blog.chart2SummaryTimeStamp4}</p>
            <p className='chart2SummaryTimeStamp'>{blog.chart2SummaryTimeStamp5}</p>
            <p className='chart2SummaryTimeStamp'>{blog.chart2SummaryTimeStamp6}</p>
            <p className='chart2SummaryTimeStamp'>{blog.chart2SummaryTimeStamp7}</p>
            <p className='chart2SummaryTimeStamp'>{blog.chart2SummaryTimeStamp8}</p>
            <p className='chart2SummaryTimeStamp'>{blog.chart2SummaryTimeStamp9}</p>
            <p className='chart2SummaryTimeStamp'>{blog.chart2SummaryTimeStamp10}</p>
            <p className='chart2SummaryTimeStamp'>{blog.chart2SummaryTimeStamp11}</p>
            <p className='chart2SummaryTimeStamp'>{blog.chart2SummaryTimeStamp12}</p>
            <p className='chart2SummaryTimeStamp'>{blog.chart2SummaryTimeStamp13}</p>
            <p className='chart2SummaryTimeStamp'>{blog.chart2SummaryTimeStamp14}</p>
            <p className='chart2SummaryTimeStamp'>{blog.chart2SummaryTimeStamp15}</p>
            <p className='chart2SummaryTimeStamp'>{blog.chart2SummaryTimeStamp16}</p>
            <p className='chart2SummaryTimeStamp'>{blog.chart2SummaryTimeStamp17}</p>
            <p className='chart2SummaryTimeStamp'>{blog.chart2SummaryTimeStamp18}</p>
            <p className='chart2SummaryTimeStamp'>{blog.chart2SummaryTimeStamp19}</p>
            <p className='chart2SummaryTimeStamp'>{blog.chart2SummaryTimeStamp20}</p>
          </div>
          <br />
          <hr />
          
          {/* --------------- CHART 3 --------------- */}
          {/* S&P 500 --- ES */}
          {/* <h2 className='myh2 myYellow'>Chart 3</h2>
          <h5 className='myh5 chart3InstrumentType instrumentColorType'>{blog.chart3InstrumentType}</h5>
          <p className='myParagraph'></p>
          <h6 className='myh6'>Chart arrow color explanation</h6>
          <p className='explanation arrow arrowLime'>&#8593; Win - Long scalp</p>
          <p className='explanation arrow arrowRed'>&#8595; Win - Short scalp</p>
          <p className='explanation arrow arrowGreen'>&#8593;&#8595; An aggressive setup (aggressive signal bar but entry based on context)</p>
          <p className='explanation arrow arrowGold'>&#8593;&#8595; Loss - Full stop</p> */}
          {/* <p className='explanation arrow arrowCyan'>&#8593;&#8595; My actual trades.</p>
          <p className='myParagraph'>I'm not currently trading the ES chart, I only focus on CL at the moment.</p> */}
          {/* <br />
          <div><img src={blog.chart3InstrumentImage} alt='Chart 3 goes here...' /></div>
          <div className='explanation yesterdaysCloseDarkOrange'>The goldenrod line displays yesterdays close.</div>
          <div className='explanation todayOpenDarkRed'>The gold line displays todays open.</div>
          <div className='explanation mySessionArea'>The white area displays my session (US open from 15:30-18:00 (CET +1 hour)).</div>
          <br />
          <p className='myh4 SignificantTimeStamps'></p>
          <div className='myParagraph'>
            <p className='chart3SummaryTimeStamp'>{blog.chart3SummaryTimeStamp1}</p>
            <p className='chart3SummaryTimeStamp'>{blog.chart3SummaryTimeStamp2}</p>
            <p className='chart3SummaryTimeStamp'>{blog.chart3SummaryTimeStamp3}</p>
            <p className='chart3SummaryTimeStamp'>{blog.chart3SummaryTimeStamp4}</p>
            <p className='chart3SummaryTimeStamp'>{blog.chart3SummaryTimeStamp5}</p>
            <p className='chart3SummaryTimeStamp'>{blog.chart3SummaryTimeStamp6}</p>
            <p className='chart3SummaryTimeStamp'>{blog.chart3SummaryTimeStamp7}</p>
            <p className='chart3SummaryTimeStamp'>{blog.chart3SummaryTimeStamp8}</p>
            <p className='chart3SummaryTimeStamp'>{blog.chart3SummaryTimeStamp9}</p>
            <p className='chart3SummaryTimeStamp'>{blog.chart3SummaryTimeStamp10}</p>
            <p className='chart3SummaryTimeStamp'>{blog.chart3SummaryTimeStamp11}</p>
            <p className='chart3SummaryTimeStamp'>{blog.chart3SummaryTimeStamp12}</p>
            <p className='chart3SummaryTimeStamp'>{blog.chart3SummaryTimeStamp13}</p>
            <p className='chart3SummaryTimeStamp'>{blog.chart3SummaryTimeStamp14}</p>
            <p className='chart3SummaryTimeStamp'>{blog.chart3SummaryTimeStamp15}</p>
            <p className='chart3SummaryTimeStamp'>{blog.chart3SummaryTimeStamp16}</p>
            <p className='chart3SummaryTimeStamp'>{blog.chart3SummaryTimeStamp17}</p>
            <p className='chart3SummaryTimeStamp'>{blog.chart3SummaryTimeStamp18}</p>
            <p className='chart3SummaryTimeStamp'>{blog.chart3SummaryTimeStamp19}</p>
            <p className='chart3SummaryTimeStamp'>{blog.chart3SummaryTimeStamp20}</p>
          </div>
          <br />
          <hr /> */}

          {/* --------------- Individual trade analysis --------------- */}
          {/*<h2 className='myh2 myYellow'>My Trades</h2>*/}
          <h4 className='myh4 tradeHeader'>{blog.trade1Header}</h4>
          <div className='statsMargin'>
            <div className='myListItem tradeHeader'>{blog.trade1SubHeader}</div>
            <div className='myListItem NumOfAccounts'>{blog.trade1NumOfAccounts}</div>
            <div className='myListItem tradedContracts'>{blog.trade1tradedContracts}</div>
            <div className='myListItem tradeRR'>{blog.trade1RR}</div>
            <div className='myListItem timeOfEntry'>{blog.trade1TimeOfEntry}</div>
            <div className='myListItem typeOfTrade'>{blog.trade1TypeOfTrade}</div>
            <div className='myListItem usedRiskManagement'>{blog.usedRiskManagement}</div>
            <div className='myListItem tradeScalp'>{blog.trade1TradeScalp}
                <span className='tradeRunner'>{blog.trade1TradeRunner}</span>
            </div>
            <h4 className='myh4 totalTradeTicks'>{blog.trade1TotalTradeTicks}</h4>
          </div>
          <img className='tradeImg' src={blog.trade1Img} alt='' />
          <p className='tradeText'>{blog.trade1}</p>
          <p className='tradeText'>{blog.reasonToTakeTrade1}</p>
          <p className='tradeText'>{blog.riskManagement1}</p>
          {/*-------------------------------------------------------*/}
          <h4 className='myh4 tradeHeader'>{blog.trade2Header}</h4>
          <div className='statsMargin'>
            <div className='myListItem tradeHeader'>{blog.trade2SubHeader}</div>
            <div className='myListItem NumOfAccounts'>{blog.trade2NumOfAccounts}</div>
            <div className='myListItem tradedContracts'>{blog.trade2tradedContracts}</div>
            <div className='myListItem tradeRR'>{blog.trade2RR}</div>
            <div className='myListItem timeOfEntry'>{blog.trade2TimeOfEntry}</div>
            <div className='myListItem typeOfTrade'>{blog.trade2TypeOfTrade}</div>
            <div className='myListItem tradeScalp'>{blog.trade2TradeScalp}
                <span className='tradeRunner'>{blog.trade2TradeRunner}</span>
            </div>
            <h4 className='myh4 totalTradeTicks'>{blog.trade2TotalTradeTicks}</h4>
          </div>
          <img className='tradeImg' src={blog.trade2Img} alt='' />
          <p className='tradeText'>{blog.trade2}</p>
          <p className='tradeText'>{blog.reasonToTakeTrade2}</p>
          <p className='tradeText'>{blog.riskManagement2}</p>
          {/*-------------------------------------------------------*/}
          <h4 className='myh4 tradeHeader'>{blog.trade3Header}</h4>
          <div className='statsMargin'>
            <div className='myListItem tradeHeader'>{blog.trade3SubHeader}</div>
            <div className='myListItem NumOfAccounts'>{blog.trade3NumOfAccounts}</div>
            <div className='myListItem tradedContracts'>{blog.trade3tradedContracts}</div>
            <div className='myListItem tradeRR'>{blog.trade3RR}</div>
            <div className='myListItem timeOfEntry'>{blog.trade3TimeOfEntry}</div>
            <div className='myListItem typeOfTrade'>{blog.trade3TypeOfTrade}</div>
            <div className='myListItem tradeScalp'>{blog.trade3TradeScalp}
                <span className='tradeRunner'>{blog.trade3TradeRunner}</span>
            </div>
            <h4 className='myh4 totalTradeTicks'>{blog.trade3TotalTradeTicks}</h4>
          </div>
          <img className='tradeImg' src={blog.trade3Img} alt='' />
          <p className='tradeText'>{blog.trade3}</p>
          <p className='tradeText'>{blog.reasonToTakeTrade3}</p>
          <p className='tradeText'>{blog.riskManagement3}</p>
          {/*-------------------------------------------------------*/}
          <h4 className='myh4 tradeHeader'>{blog.trade4Header}</h4>
          <div className='statsMargin'>
            <div className='myListItem tradeHeader'>{blog.trade4SubHeader}</div>
            <div className='myListItem NumOfAccounts'>{blog.trade4NumOfAccounts}</div>
            <div className='myListItem tradedContracts'>{blog.trade4tradedContracts}</div>
            <div className='myListItem tradeRR'>{blog.trade4RR}</div>
            <div className='myListItem timeOfEntry'>{blog.trade4TimeOfEntry}</div>
            <div className='myListItem typeOfTrade'>{blog.trade4TypeOfTrade}</div>
            <div className='myListItem tradeScalp'>{blog.trade4TradeScalp}
                <span className='tradeRunner'>{blog.trade4TradeRunner}</span>
            </div>
            <h4 className='myh4 totalTradeTicks'>{blog.trade4TotalTradeTicks}</h4>
          </div>
          <img className='tradeImg' src={blog.trade4Img} alt='' />
          <p className='tradeText'>{blog.trade4}</p>
          <p className='tradeText'>{blog.reasonToTakeTrade4}</p>
          <p className='tradeText'>{blog.riskManagement4}</p>
          {/*-------------------------------------------------------*/}
          <h4 className='myh4 tradeHeader'>{blog.trade5Header}</h4>
          <div className='statsMargin'>
            <div className='myListItem tradeHeader'>{blog.trade5SubHeader}</div>
            <div className='myListItem NumOfAccounts'>{blog.trade5NumOfAccounts}</div>
            <div className='myListItem tradedContracts'>{blog.trade5tradedContracts}</div>
            <div className='myListItem tradeRR'>{blog.trade5RR}</div>
            <div className='myListItem timeOfEntry'>{blog.trade5TimeOfEntry}</div>
            <div className='myListItem typeOfTrade'>{blog.trade5TypeOfTrade}</div>
            <div className='myListItem tradeScalp'>{blog.trade5TradeScalp}
                <span className='tradeRunner'>{blog.trade5TradeRunner}</span>
            </div>
            <h4 className='myh4 totalTradeTicks'>{blog.trade5TotalTradeTicks}</h4>
          </div>
          <img className='tradeImg' src={blog.trade5Img} alt='' />
          <p className='tradeText'>{blog.trade5}</p>
          <p className='tradeText'>{blog.reasonToTakeTrade5}</p>
          <p className='tradeText'>{blog.riskManagement5}</p>
          {/*-------------------------------------------------------*/}
          <h4 className='myh4 tradeHeader'>{blog.trade6Header}</h4>
          <div className='statsMargin'>
            <div className='myListItem tradeHeader'>{blog.trade6SubHeader}</div>
            <div className='myListItem NumOfAccounts'>{blog.trade6NumOfAccounts}</div>
            <div className='myListItem tradedContracts'>{blog.trade6tradedContracts}</div>
            <div className='myListItem tradeRR'>{blog.trade6RR}</div>
            <div className='myListItem timeOfEntry'>{blog.trade6TimeOfEntry}</div>
            <div className='myListItem typeOfTrade'>{blog.trade6TypeOfTrade}</div>
            <div className='myListItem tradeScalp'>{blog.trade6TradeScalp}
                <span className='tradeRunner'>{blog.trade6TradeRunner}</span>
            </div>
            <h4 className='myh4 totalTradeTicks'>{blog.trade6TotalTradeTicks}</h4>
          </div>
          <img className='tradeImg' src={blog.trade6Img} alt='' />
          <p className='tradeText'>{blog.trade6}</p>
          <p className='tradeText'>{blog.reasonToTakeTrade6}</p>
          <p className='tradeText'>{blog.riskManagement6}</p>
          {/*-------------------------------------------------------*/}
          <h4 className='myh4 tradeHeader'>{blog.trade7Header}</h4>
          <div className='statsMargin'>
            <div className='myListItem tradeHeader'>{blog.trade7SubHeader}</div>
            <div className='myListItem NumOfAccounts'>{blog.trade7NumOfAccounts}</div>
            <div className='myListItem tradedContracts'>{blog.trade7tradedContracts}</div>
            <div className='myListItem tradeRR'>{blog.trade7RR}</div>
            <div className='myListItem timeOfEntry'>{blog.trade7TimeOfEntry}</div>
            <div className='myListItem typeOfTrade'>{blog.trade7TypeOfTrade}</div>
            <div className='myListItem tradeScalp'>{blog.trade7TradeScalp}
                <span className='tradeRunner'>{blog.trade7TradeRunner}</span>
            </div>
            <h4 className='myh4 totalTradeTicks'>{blog.trade7TotalTradeTicks}</h4>
          </div>
          <img className='tradeImg' src={blog.trade7Img} alt='' />
          <p className='tradeText'>{blog.trade7}</p>
          <p className='tradeText'>{blog.reasonToTakeTrade7}</p>
          <p className='tradeText'>{blog.riskManagement7}</p>
          {/*-------------------------------------------------------*/}
          <h4 className='myh4 tradeHeader'>{blog.trade8Header}</h4>
          <div className='statsMargin'>
            <div className='myListItem tradeHeader'>{blog.trade8SubHeader}</div>
            <div className='myListItem NumOfAccounts'>{blog.trade8NumOfAccounts}</div>
            <div className='myListItem tradedContracts'>{blog.trade8tradedContracts}</div>
            <div className='myListItem tradeRR'>{blog.trade8RR}</div>
            <div className='myListItem timeOfEntry'>{blog.trade8TimeOfEntry}</div>
            <div className='myListItem typeOfTrade'>{blog.trade8TypeOfTrade}</div>
            <div className='myListItem tradeScalp'>{blog.trade8TradeScalp}
                <span className='tradeRunner'>{blog.trade8TradeRunner}</span>
            </div>
            <h4 className='myh4 totalTradeTicks'>{blog.trade8TotalTradeTicks}</h4>
          </div>
          <img className='tradeImg' src={blog.trade8Img} alt='' />
          <p className='tradeText'>{blog.trade8}</p>
          <p className='tradeText'>{blog.reasonToTakeTrade8}</p>
          <p className='tradeText'>{blog.riskManagement8}</p>
          {/*-------------------------------------------------------*/}
          <h4 className='myh4 tradeHeader'>{blog.trade9Header}</h4>
          <div className='statsMargin'>
            <div className='myListItem tradeHeader'>{blog.trade9SubHeader}</div>
            <div className='myListItem NumOfAccounts'>{blog.trade9NumOfAccounts}</div>
            <div className='myListItem tradedContracts'>{blog.trade9tradedContracts}</div>
            <div className='myListItem tradeRR'>{blog.trade9RR}</div>
            <div className='myListItem timeOfEntry'>{blog.trade9TimeOfEntry}</div>
            <div className='myListItem typeOfTrade'>{blog.trade9TypeOfTrade}</div>
            <div className='myListItem tradeScalp'>{blog.trade9TradeScalp}
                <span className='tradeRunner'>{blog.trade9TradeRunner}</span>
            </div>
            <h4 className='myh4 totalTradeTicks'>{blog.trade9TotalTradeTicks}</h4>
          </div>
          <img className='tradeImg' src={blog.trade9Img} alt='' />
          <p className='tradeText'>{blog.trade9}</p>
          <p className='tradeText'>{blog.reasonToTakeTrade9}</p>
          <p className='tradeText'>{blog.riskManagement9}</p>
          {/*-------------------------------------------------------*/}
          <h4 className='myh4 tradeHeader'>{blog.trade10Header}</h4>
          <div className='statsMargin'>
            <div className='myListItem tradeHeader'>{blog.trade10SubHeader}</div>
            <div className='myListItem NumOfAccounts'>{blog.trade10NumOfAccounts}</div>
            <div className='myListItem tradedContracts'>{blog.trade10tradedContracts}</div>
            <div className='myListItem tradeRR'>{blog.trade10RR}</div>
            <div className='myListItem timeOfEntry'>{blog.trade10TimeOfEntry}</div>
            <div className='myListItem typeOfTrade'>{blog.trade10TypeOfTrade}</div>
            <div className='myListItem tradeScalp'>{blog.trade10TradeScalp}
                <span className='tradeRunner'>{blog.trade10TradeRunner}</span>
            </div>
            <h4 className='myh4 totalTradeTicks'>{blog.trade10TotalTradeTicks}</h4>
          </div>
          <img className='tradeImg' src={blog.trade10Img} alt='' />
          <p className='tradeText'>{blog.trade10}</p>
          <p className='tradeText'>{blog.reasonToTakeTrade10}</p>
          <p className='tradeText'>{blog.riskManagement10}</p>
        
          <Link className='blog-goBack center' to='/'>
            <span> &#8592; Home</span>
          </Link>
        </div>
      ) : (
        <EmptyList />
      )}
    </>
  );
};

export default Blog

